























































































































































































import { defineComponent, computed, ref, reactive, toRefs, inject } from '@vue/composition-api';
import axios from 'axios';
import { useUserState } from '@/store';

export default defineComponent({
  name: 'MTeamTable',

  props: {
    data: {
      required: true,
      type: Object
    },
    viewOnly: {
      type: Boolean,
      default: false
    },
    fetchDeliverable: {
      type: Function,
      default: () => {}
    }
  },
  setup(props: any, ctx) {
    const readonly = inject('readonly', false);
    const requestAccepted = ref(false);
    const requestDeclined = ref(false);
    const isRemoving = ref(false);
    const state = reactive({
      selectedMember: null as null | Record<string, any>,
      removeMemberDialog: false,
      changeOwnerDialog: false
    });
    const { user }: any = useUserState(['user']);
    const memberAction = ref('');
    const isRequesting = ref(false);
    const currentUserId = user.value?._id.toString();
    const isOwner = (item: Record<string, any>) => {
      return item?._id === props.data?.ownerId;
    };
    const tableHeaders = computed(() => {
      const headers = [
        { text: '', align: 'start', value: 'avatar', width: '5%' },
        { text: 'Name', align: 'start', value: 'name', width: '50%' },
        {
          text: 'Access',
          align: 'start',
          value: 'access',
          sortable: false,
          width: '20%'
        },
        {
          text: 'Action',
          align: 'start',
          value: 'action',
          sortable: false,
          width: '25%'
        }
      ];
      if (user.value?._id.toString() === props.data.ownerId.toString()) return headers;
      return headers.filter(column => column.value !== 'action');
    });
    const teamMembers = computed(() => {
      return props.data.members.slice().sort(member => {
        return isOwner(member) ? -1 : 1;
      });
    });
    const teamMembersExceptOwner = computed(() => {
      return props.data.members.filter(member => {
        return !isOwner(member);
      });
    });
    const removeMember = async () => {
      try {
        isRemoving.value = true;
        const API_ENDPOINT = process.env.VUE_APP_DELIVERABLE_REMOVE_MEMBER;

        const res = await axios.delete(
          `${API_ENDPOINT}?deliverable_id=${props.data._id}&member_id=${state.selectedMember?._id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );

        if (res.data.data) {
          state.removeMemberDialog = false;
          state.selectedMember = null;
          isRemoving.value = true;
          props.fetchDeliverable(props.data);
        }
      } catch (error) {
        console.log(error);
        isRemoving.value = true;
      }
    };

    const changeOwner = () => {
      if (state.selectedMember) {
        // ctx.emit('changeOwner', state.selectedMember._id);
        state.changeOwnerDialog = false;
        state.selectedMember = null;
      }
    };

    async function manageMembership(event, item) {
      try {
        const API_ENDPOINT: any = process.env.VUE_APP_DELIVERABLE_MEMBERSHIP;
        isRequesting.value = true;
        const data = {
          member_id: item._id,
          deliverable_id: props.data._id,
          request_type: event.toLowerCase()
        };

        const res = await axios.patch(API_ENDPOINT, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });

        if (res.data.data) {
          isRequesting.value = false;
          if (event === 'Accept') {
            requestAccepted.value = true;
            ctx.emit('accept');
          } else {
            requestDeclined.value = true;
          }
          props.fetchDeliverable(props.data);
        }
      } catch (error) {
        console.log(error);
        isRequesting.value = false;
      }
    }

    return {
      tableHeaders,
      teamMembers,
      teamMembersExceptOwner,
      isOwner,
      removeMember,
      changeOwner,
      user,
      readonly,
      currentUserId,
      memberAction,
      isRequesting,
      manageMembership,
      requestAccepted,
      requestDeclined,
      isRemoving,
      ...toRefs(state)
    };
  }
});
