


















































































































import { ref, computed } from '@vue/composition-api';
import axios from 'axios';
import { useUserState } from '@/store';
import MTeamTable from '@/components/molecules/m-team-table.vue';

export default {
  components: {
    MTeamTable
  },

  props: {
    data: {
      type: [Object, Array],
      default: () => {}
    },
    viewOnly: {
      type: Boolean,
      default: false
    },
    student: {
      required: false,
      type: Object,
      default: () => {}
    },
    fetchDeliverables: {
      type: Function,
      default: () => {}
    },
    fetchDeliverable: {
      type: Function,
      default: () => {}
    },
    isMonitor: {
      type: Boolean,
      default: false
    }
  },

  setup(props: any, ctx: any) {
    const isDeleting = ref(false);
    const isLoading = ref(false);
    const deliverableName = ref('');
    const showDeleteConfirmation = ref(false);
    const { user }: any = useUserState(['user']);
    const requestSent = ref(false);
    const isRequesting = ref(false);
    const isRequestSent = ref(false);
    const validate = ref();

    function checkRequestStatus() {
      const isMember = props.data?.members?.filter(
        m => m._id === user.value._id.toString() || props.student?.data?.participant_id
      )[0];
      if (isMember?.isMembershipPending) {
        isRequestSent.value = true;
      } else {
        isRequestSent.value = false;
      }
    }

    checkRequestStatus();

    async function deleteDeliverable() {
      isDeleting.value = true;
      try {
        const ENDPOINT_API = process.env.VUE_APP_DELIVERABLE;
        const res = await axios.delete(
          `${ENDPOINT_API}?deliverable_id=${props.data._id}&public_id=${props?.student?.data?._id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        if (res.data.data) {
          await props.fetchDeliverables();
          isDeleting.value = false;
          showDeleteConfirmation.value = false;
          ctx.emit('setTab');
        }
      } catch (error) {
        console.log(error);
        isDeleting.value = false;
      }
    }

    async function renameDeliverable() {
      isLoading.value = true;
      try {
        const data = {
          name: deliverableName.value
        };
        const ENDPOINT_API = process.env.VUE_APP_DELIVERABLE_RENAME;
        const res = await axios.patch(
          `${ENDPOINT_API}?deliverable_id=${props.data._id}&public_id=${props?.student?.data._id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        if (res.data.data) {
          ctx.emit('update', res.data.data);
          deliverableName.value = '';
          validate.value.reset();
          isLoading.value = false;
        }
      } catch (error) {
        console.log(error);
        isLoading.value = false;
      }
    }

    const isOwner = computed(
      () =>
        props.data?.ownerId === user.value?._id.toString() || props.student?.data?.participant_id
    );

    async function sendJoinRequest() {
      try {
        const API_ENDPOINT: any = process.env.VUE_APP_DELIVERABLE_MEMBERSHIP;
        isRequesting.value = true;
        const data = {
          student_id: props?.student?.data._id,
          public_id: props?.student?.data._id,
          deliverable_id: props.data._id,
          request_type: 'join'
        };

        const res = await axios.patch(API_ENDPOINT, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });

        if (res.data.data) {
          requestSent.value = true;
          isRequesting.value = false;
          isRequestSent.value = true;
        }
      } catch (error) {
        console.log(error);
        isRequesting.value = false;
      }
    }

    return {
      isDeleting,
      deleteDeliverable,
      deliverableName,
      renameDeliverable,
      isLoading,
      showDeleteConfirmation,
      isOwner,
      sendJoinRequest,
      isRequesting,
      requestSent,
      isRequestSent,
      checkRequestStatus,
      validate
    };
  }
};
